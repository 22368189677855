//故障返修详情
<template>
  <div>
    <el-descriptions title="故障返修申请" border :colon="false">
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人：">
        {{ orderResult.proposerName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人部门：">
        {{ orderResult.applyDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="日期：">
        {{ orderResult.applyTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="客户名称：">
        {{ orderResult.clientName || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="发货人：">
        {{ orderResult.consigner }}
      </el-descriptions-item>
      <el-descriptions-item label="发货人联系方式：">
        {{ orderResult.consignerMobile }}
      </el-descriptions-item>
      <el-descriptions-item label="物流单号：">
        {{ orderResult.trackingNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="收货人：">
        {{ orderResult.consignee }}
      </el-descriptions-item>
      <el-descriptions-item label="联系电话：">
        {{ orderResult.consigneeMobile }}
      </el-descriptions-item>
      <el-descriptions-item label="收货地址：">
        {{ orderResult.shippingAddress }}
      </el-descriptions-item>
      <el-descriptions-item label="加急维修：">
        {{ orderResult.urgentMaintenance == 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item label="加急快递：">
        {{ orderResult.urgentExpress == 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      申请物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="detailResults">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="设备料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="设备名称"
      />
      <el-table-column header-align="center" align="center" label="设备sn">
        <template slot-scope="{ row }">
          {{ row.sn || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="返修数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="是否在保">
        <template slot-scope="{ row }">
          {{ row.insuredStatus == 1 ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="故障详情">
        <template slot-scope="{ row }">
          {{ row.detail || '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
