//设备形态转换申请详情
<template>
  <div>
    <el-descriptions title="设备形态转换申请" border :colon="false">
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="U8单号：">
        {{ orderResult.u8OrderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人：">
        {{ orderResult.proposerName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请部门：">
        {{ orderResult.applyDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="日期：">
        {{ orderResult.orderTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="入库类别：">
        {{ orderResult.inStorageName }}
      </el-descriptions-item>
      <el-descriptions-item label="出库类别：">
        {{ orderResult.outStorageName }}
      </el-descriptions-item>
      <el-descriptions-item label="是否可本人转换：">
        {{ orderResult.selfConvertType == 1 ? '是' : '否' }}
      </el-descriptions-item>

      <el-descriptions-item label="申请理由：">
        {{ orderResult.applyReason || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
      <el-descriptions-item label="转换方式：">
        {{ orderResult.type == 2 ? '多对一' : '一对一' }}
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      申请物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="tableData">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="现有料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="现有物品名称"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="warehouseName"
        label="所属仓库"
      />
      <el-table-column header-align="center" align="center" label="货位">
        <template slot-scope="{ row }">
          {{ row.storageName || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="现有物品sn">
        <template slot-scope="{ row }">
          {{ row.oldSn || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="转换数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
    </el-table>
    <el-table :data="tableData2">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="转换料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="转换物品名称"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="warehouseName"
        label="所属仓库"
      />
      <el-table-column header-align="center" align="center" label="货位">
        <template slot-scope="{ row }">
          {{ row.storageName || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="转换物品sn">
        <template slot-scope="{ row }">
          {{ row.newSn || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="转换数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  computed: {
    tableData() {
      const detailResults = this.detailResults
      return (
        detailResults.filter(
          (a, index) => index !== detailResults.length - 1
        ) || []
      )
    },
    tableData2() {
      const detailResults = this.detailResults
      if (detailResults.length) {
        return [detailResults[detailResults.length - 1]]
      }
      return []
    }
  },
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
