<template>
  <el-dialog
    :close-on-click-modal="false"
    :title="auditType === 1 ? '审核通过' : '审核驳回'"
    :visible.sync="show"
    width="550px"
    :before-close="handleClose"
  >
    <el-form ref="form" label-position="top" :model="params" :rules="rules">
      <el-form-item label="审批备注" :prop="auditType != 1 ? 'comments' : ''">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="请输入审批备注"
          v-model="params.comments"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm" v-loading="loading">
        确定
      </el-button>
      <el-button @click="handleClose">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '../../../mixins/dialogCommonParams.js'
export default {
  mixins: [dialogCommonParams],
  props: {
    auditType: {
      type: Number,
      default: 1 //1通过，2驳回
    },
    processInstanceId: {
      required: true,
      type: String,
      default: ''
    },
    taskId: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      params: {},
      rules: {
        comments: [{ required: true, message: '请输入审批备注' }]
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.loading = true
            const { processCode, type } = this.$route.query
            if (
              this.auditType == 1 &&
              (type == 3 || type == 4 || type == 5 || type == 7)
            ) {
              //服务配件归还申请、 服务收费单、设备形态转换申请、领料申请 审核通过前判断数量能否审核通过
              let errData = await this.$api.apply.checkNum({
                processCode,
                type,
                processInstanceId: this.processInstanceId
              })
              if (errData && errData.length) {
                this.$emit(
                  'error',
                  errData.map(msg => {
                    return { msg }
                  })
                )
                this.show = false
                return
              }
            }
            if (this.auditType == 1) {
              await this.$api.flowable.approved({
                ...this.params,
                taskId: this.taskId
              })
            } else {
              await this.$api.flowable.rollback({
                ...this.params,
                taskId: this.taskId
              })
            }
            this.$message.success('提交成功')
            this.$emit('updateList')
            this.show = false
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        }
      })
    },
    handleClose(){
      this.$emit('updateList')
      this.show=false
    }
  }
}
</script>

<style lang="less" scoped></style>
