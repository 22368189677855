//点击编辑时，页面顶部数据
<template>
  <div class="auditHeader" v-loading="loading">
    <div class="cm-flex-line">
      <div>
        <div class="label">审批状态：</div>
        <div class="val">{{ applyResult.statusStr }}</div>
      </div>
      <div v-if="applyResult.invalidating == 0">
        <div class="label">审批人：</div>
        <div class="val">{{ applyResult.auditUserName }}</div>
      </div>
      <div v-if="applyResult.invalidating == 0">
        <div class="label">审批时间：</div>
        <div class="val">
          {{ applyResult.updateTime | date('YYYY-mm-dd HH:MM:SS') }}
        </div>
      </div>
    </div>
    <div class="cm-flex-line">
      <div v-if="applyResult.invalidating == 0">
        <div class="label">驳回原因：</div>
        <div class="val" style="color: red">
          {{ applyResult.comments }}
        </div>
      </div>
      <div v-else>
        <div class="label">作废原因：</div>
        <div class="val" style="color: red">
          {{ applyResult.remark }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    applyResult: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false
    }
  },
}
</script>

<style lang="less" scoped>
.auditHeader {
  border-bottom: 1px solid #f5f6f6;
}
.cm-flex-line {
  margin-bottom: 20px;
  & > div {
    display: flex;
    align-items: center;
    .label {
      width: 140px;
      padding-right: 12px;
      text-align: right;
      box-sizing: border-box;
      color: #606266;
    }
  }
}
</style>
