//备机|配件详情
<template>
  <div>
    <el-descriptions :title="title" border :colon="false">
      <el-descriptions-item
        label="作废原因："
        :span="5"
        v-if="[11, 21].includes(type)"
      >
        {{ applyResult.remark }}
      </el-descriptions-item>
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="U8单号：">
        {{ orderResult.u8AllotOrderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人：">
        {{ orderResult.proposerName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人部门：">
        {{ orderResult.applyDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="日期：">
        {{ orderResult.applyTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="客户名称（全称）：">
        {{ orderResult.clientName }}
      </el-descriptions-item>
      <el-descriptions-item label="收货人：">
        {{ orderResult.consignee }}
      </el-descriptions-item>
      <el-descriptions-item label="预计归还日期：">
        {{ orderResult.expectedReturnTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="联系电话：">
        {{ orderResult.consigneeMobile }}
      </el-descriptions-item>
      <el-descriptions-item label="收货地址：">
        {{ orderResult.shippingAddress }}
      </el-descriptions-item>
      <el-descriptions-item label="申请理由：">
        {{ orderResult.applyReason }}
      </el-descriptions-item>
      <el-descriptions-item label="加急快递：">
        {{ orderResult.urgentExpress === 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item label="转入仓库：">
        {{ orderResult.inWarehouseName }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      申请物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="detailResults">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="物品名称"
      />
      <el-table-column header-align="center" align="center" label="申请数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="转入货位">
        <template slot-scope="{ row }">
          {{ row.inPositionName || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="{ row }">
          {{ row.remark || '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  props: ['type'],
  computed: {
    title() {
      return {
        1: '备机申请',
        11: '备机申请作废',
        2: '配件申请',
        21: '配件申请作废'
      }[this.type]
    }
  },
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
