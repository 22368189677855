//其他入库详情
<template>
  <div>
    <el-descriptions title="其他入库申请" border :colon="false">
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="U8单号：">
        {{ orderResult.u8OrderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="入库日期：">
        {{ orderResult.orderTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="入库仓库：">
        {{ orderResult.inWarehouseName }}
      </el-descriptions-item>
      <el-descriptions-item label="入库类别：">
        {{ orderResult.storageName }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      入库物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="detailResults">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="存货编码"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="存货名称"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="positionName"
        label="货位"
      />
      <el-table-column header-align="center" align="center" label="数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="sn"
        label="序列号"
      />
      <el-table-column header-align="center" align="center" label="单价">
        <template slot-scope="{ row }">
          {{ (row.amount / 100) | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="金额">
        <template slot-scope="{ row }">
          {{ (row.totalAmount / 100) | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="{ row }">
          {{ row.remark || '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
