// 导出页面为PDF格式
import html2Canvas from 'html2canvas'
import JsPDF from 'jspdf'

export const exportPdf = async (fileName, elem, callback = () => {}) => {
  if (!elem) return
  fileName = fileName || new Date().getTime()
  //上下边距余白
  const topBtmVoid = 20
  //创绘制切割后绘制canvas用的canvas标签以及对应的context对象
  const perCanvas = document.createElement('canvas')
  perCanvas.style.backgroundColor = '#fff'
  const context = perCanvas.getContext('2d')

  // 将需要下载的html标签转成canvas标签，并获取对应的base64码
  const canvas = await html2Canvas(elem)
  const canvasData = canvas.toDataURL('image/jpeg', 1.0)

  // pdf的尺寸
  const pdfWidth = canvas.width
  const pdfHeight = pdfWidth * 1.414

  //切割后的canvas图片的宽高，就等于每页pdf的宽高
  perCanvas.width = pdfWidth
  perCanvas.height = pdfHeight

  // 每张图片的高度：适当减少topBtmVoid * 2，上下各留topBtmVoid边距
  const perHeight = pdfHeight - topBtmVoid * 2

  // 计算切割次数
  let splitCount = Math.ceil(canvas.height / perHeight)
  if (splitCount * perHeight < canvas.height) splitCount++

  //创建img对象，加载完整的canvas图片
  const img = new Image()
  img.src = canvasData

  //图片加载完成
  img.onload = function () {
    //创建pdf对象
    const pdf = new JsPDF('p', 'pt', [pdfWidth, pdfHeight])

    //切割canvas图片，贴到每一页pdf中
    for (let i = 0; i < splitCount; i++) {
      const startY = i * perHeight // 起始y坐标
      // 清空画布
      context.clearRect(0, 0, perCanvas.width, pdfHeight)
      context.fillStyle = '#fff'
      context.fillRect(0, 0, perCanvas.width, pdfHeight)
      // 绘制当前切割区域的图片
      context.drawImage(
        img,
        0,
        startY,
        perCanvas.width,
        perHeight,
        0,
        0,
        perCanvas.width,
        perHeight
      )
      const perCanvasData = perCanvas.toDataURL('image/jpeg', 1.0)
      pdf.addImage(
        perCanvasData,
        'JPEG',
        0,
        topBtmVoid,
        perCanvas.width,
        perCanvas.height
      )
      if (i < splitCount - 1) pdf.addPage()
    }

    pdf.save(fileName + '.pdf')

    callback && callback()
  }
}

export const exportPdf2 = async (fileName, element, callback = () => {}) => {
  if (!element) return
  fileName = fileName || new Date().getTime()

  // 尺寸的确定
  const originWidth = element.offsetWidth || 700
  // 创建一个容器，用于克隆元素
  const container = document.createElement('div')
  // 16px是为了生成的PDF有安全边距
  container.style.cssText = `position:fixed;left: ${
    -2 * originWidth
  }px; top:0;padding:16px;width:${originWidth}px;box-sizing:content-box;`
  // 插入到body中
  document.body.appendChild(container)
  // 克隆元素
  container.appendChild(element.cloneNode(true))
  // 为了保证显示质量，2倍PDF尺寸
  const scale = 2
  const width = originWidth + 32

  const PDF_WIDTH = width * scale
  const PDF_HEIGHT = PDF_WIDTH * 1.414

  // 渲染为图片并下载
  html2Canvas(container, {
    allowTaint: true,
    scale: scale
  }).then(function (canvas) {
    const contentWidth = canvas.width
    const contentHeight = canvas.height

    // 一页pdf显示html页面生成的canvas高度
    const pageHeight = (contentWidth / PDF_WIDTH) * PDF_HEIGHT

    // canvas图像在画布上的尺寸
    const imgWidth = PDF_WIDTH
    const imgHeight = (PDF_WIDTH / contentWidth) * contentHeight

    let leftHeight = contentHeight
    let position = 0

    const doc = new JsPDF('p', 'px', [PDF_WIDTH, PDF_HEIGHT])

    // 不足一页
    if (leftHeight < pageHeight) {
      doc.addImage(canvas, 'JPEG', 0, 0, imgWidth, imgHeight)
    } else {
      // 多页
      while (leftHeight > 0) {
        doc.addImage(canvas, 'JPEG', 0, position, imgWidth, imgHeight)
        leftHeight -= pageHeight
        position -= PDF_HEIGHT
        //避免添加空白页
        if (leftHeight > 0) {
          doc.addPage()
        }
      }
    }

    doc.save(fileName + '.pdf')

    // 移除创建的元素
    container.remove()

    // 隐藏全局loading提示
    callback()
  })
}
