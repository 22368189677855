//领料申请(销库)详情
<template>
  <div>
    <el-descriptions
      :title="type === 7 ? '领料申请(销库)' : '领料申请(销库)作废'"
      border
      :colon="false"
    >
      <el-descriptions-item label="作废原因：" :span="5" v-if="type === 71">
        {{ applyResult.remark }}
      </el-descriptions-item>
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="U8单号：">
        {{ orderResult.u8OrderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人：">
        {{ orderResult.proposerName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请部门：">
        {{ orderResult.applyDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请日期：">
        {{ orderResult.applyTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="转出仓库：">
        {{ orderResult.outWarehouseName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请理由：">
        {{ orderResult.reason }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      申请物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="detailResults">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="材料料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="材料名称"
      />
      <el-table-column
        header-align="center"
        align="center"
        label="出库货位"
        :formatter="({ outPositionName }) => outPositionName || '-'"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="num"
        label="数量"
      />
      <el-table-column
        header-align="center"
        align="center"
        label="sn"
        :formatter="({ sns }) => sns || '-'"
      />
      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="{ row }">
          {{ row.remark || '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  props: ['type'],
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
