//服务收费详情
<template>
  <div>
    <el-descriptions title="服务收费单申请" border :colon="false">
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="U8销售单号：">
        {{ orderResult.u8SellCode }}
      </el-descriptions-item>
      <el-descriptions-item label="订单日期：">
        {{ orderResult.orderTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="关联工单号：">
        {{ orderResult.workOrderCode || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="销售类型：">
        {{ sellTypeStr }}
      </el-descriptions-item>
      <!-- <el-descriptions-item label="业务类型：">
        {{ businessTypeStr }}
      </el-descriptions-item> -->
      <el-descriptions-item label="销售部门：">
        {{ orderResult.salesDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="所属区域：">
        {{
          orderResult.provinceName +
          '-' +
          orderResult.cityName +
          '-' +
          orderResult.areaName
        }}
      </el-descriptions-item>
      <el-descriptions-item label="业务员：">
        {{ orderResult.salesmanName }}
      </el-descriptions-item>
      <el-descriptions-item label="上门安装：">
        {{ orderResult.installType == 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item label="保修方式：">
        {{ maintenanceTypeStr }}
      </el-descriptions-item>
      <el-descriptions-item label="客户名称：">
        {{ orderResult.clientName || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="付款方式：">
        {{ payWayStr }}
      </el-descriptions-item>
      <el-descriptions-item label="要求发货日期：">
        {{ orderResult.requireDeliverTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="联系人：">
        {{ orderResult.linkman || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="联系人手机：">
        {{ orderResult.linkmanPhone || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="联系人地址：">
        {{ orderResult.linkmanAddress || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      费用清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载费用清单
      </el-button>
    </el-divider>
    <el-table :data="detailResults">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="名称"
      />
      <el-table-column header-align="center" align="center" label="单价">
        <template slot-scope="{ row }">
          {{ (row.price / 100) | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="小计费用">
        <template slot-scope="{ row }">
          {{ (row.totalAmount / 100) | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="仓库">
        <template slot-scope="{ row }">
          {{ row.warehouseName || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="货位">
        <template slot-scope="{ row }">
          {{ row.storageName || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="{ row }">
          {{ row.remark || '-' }}
        </template>
      </el-table-column>
    </el-table>
    <el-descriptions title=" " border :colon="false">
      <el-descriptions-item label="费用合计：">
        {{ priceTotal }}
      </el-descriptions-item>
      <el-descriptions-item label="有无特价：">
        {{ orderResult.specialAmount ? '有' : '无' }}
      </el-descriptions-item>
      <template v-if="orderResult.specialAmount">
        <el-descriptions-item label="特价单号：">
          {{ orderResult.specialOrderCode || '-' }}
        </el-descriptions-item>
        <el-descriptions-item label="特价后价格：">
          {{ (orderResult.specialAmount / 100) | dividedInto }}
        </el-descriptions-item>
      </template>
    </el-descriptions>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  computed: {
    sellTypeStr() {
      let sellType = this.orderResult.sellType
      let name =
        this.$constant.sellType.find(a => a.value === sellType)?.label || ''
      return name
    },
    businessTypeStr() {
      let businessType = this.orderResult.businessType
      let name =
        this.$constant.businessType.find(a => a.value === businessType)
          ?.label || ''
      return name
    },
    maintenanceTypeStr() {
      let maintenanceType = this.orderResult.maintenanceType
      let name =
        this.$constant.maintenanceType.find(a => a.value === maintenanceType)
          ?.label || ''
      return name
    },
    payWayStr() {
      let payWay = this.orderResult.payWay
      let name =
        this.$constant.payWay.find(a => a.value === payWay)?.label || ''
      return name
    },
    priceTotal() {
      let list = this.detailResults || []
      let sum = 0
      list.forEach(a => {
        sum += a.price * a.num
      })
      return sum / 100
    }
  },
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
