export default {
  props: {
    orderResult: {
      type: Object,
      default: () => {
        return {}
      }
    },
    detailResults: {
      type: Array,
      default: () => {
        return []
      }
    },
    applyResult: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}
