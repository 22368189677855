//服务配件归还详情
<template>
  <div>
    <el-descriptions title="服务配件归还申请" border :colon="false">
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="U8单号：">
        {{ orderResult.u8AllotOrderCode }}
      </el-descriptions-item>
      <el-descriptions-item label="申请人：">
        {{ orderResult.proposerName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请部门：">
        {{ orderResult.applyDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请时间：">
        {{ orderResult.applyTime | date('YYYY-mm-dd') }}
      </el-descriptions-item>
      <el-descriptions-item label="物流单号：">
        {{ orderResult.trackingNumber }}
      </el-descriptions-item>
      <el-descriptions-item label="关联申请单：">
        {{ orderResult.applyCode }}
      </el-descriptions-item>
      <el-descriptions-item label="转出仓库：">
        {{ orderResult.outWarehouseName }}
      </el-descriptions-item>
      <el-descriptions-item label="用途：">
        {{ orderResult.purpose || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      申请物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="detailResults">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="名称"
      />
      <el-table-column header-align="center" align="center" label="主机sn">
        <template slot-scope="{ row }">
          {{ row.completeSn || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="配件sn">
        <template slot-scope="{ row }">
          {{ row.sn || '-' }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="申请数量">
        <template slot-scope="{ row }">
          {{ row.num | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="客户">
        <template slot-scope="{ row }">
          {{ row.clientName }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="故障时间">
        <template slot-scope="{ row }">
          {{ row.malfunctionTime }}
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="故障描述">
        <template slot-scope="{ row }">
          {{ row.malfunctionDesc || '-' }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  methods: {
    downalod() {
      this.$emit('export')
    }
  }
}
</script>
