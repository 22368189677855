<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="show"
    title="申请单备注"
    width="550px"
  >
    <el-form ref="form" :model="params" :rules="rules">
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model="params.remark"
          :autosize="{ minRows: 2 }"
          placeholder="请输入备注"
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item label="文件">
        <UploadFile
          ref="UploadFile"
          v-model="params.files"
          accept=".jpg,.jpeg,.png,.pdf"
          btnText="上传文件"
          :limit="10"
          :limitFileNameLength="0"
          :multiple="true"
          :preAppendixAddr="params && params.attachmentUrl"
        />
      </el-form-item>
      <el-form-item label="通知人员">
        <el-select
          v-model="params.noticer"
          filterable
          multiple
          collapse-tags
          style="margin-left: 20px; width: 80%"
          placeholder="请选择"
          @change="changeNoticer"
        >
          <el-option
            v-for="user in users"
            :label="user.nickname"
            :value="user.id"
            :key="user.id"
          ></el-option>
        </el-select>
        <el-tag
          class="selected-item"
          v-for="(noticer, index) in noticers"
          :key="index + '_selected'"
          closable
          size="mini"
          @close="removeNoticer(index)"
        >
          {{ noticer.nickname }}
        </el-tag>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitForm" v-loading="loading">
        确定
      </el-button>
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '../../../mixins/dialogCommonParams.js'
export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      loading: false,
      params: {},
      users: [],
      noticers: [],
      rules: {
        remark: [{ required: true, message: '请输入备注' }]
      }
    }
  },
  methods: {
    async init() {
      this.users = (await this.$api.flowable.listUsers()) || []
    },
    changeNoticer(selecteds) {
      this.noticers = this.users.filter(
        item => selecteds.indexOf(item.id) != -1
      )
    },
    removeNoticer(index) {
      let noticers = this.noticers
      let noticer = noticers.splice(index, 1)
      this.noticers = noticers
      this.params.noticer = this.params.noticer.filter(
        item => item != noticer.id
      )
    },
    submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            let noticer = ''
            if (this.params.noticer) {
              noticer = this.params.noticer.join()
            }
            let imgUrls = ''
            if (this.params.files) {
              imgUrls = this.params.files
                .map(item => {
                  return `${item.url}?fileName=${item.name}`
                })
                .join()
            }
            this.loading = true
            let params = {
              orderCode: this.currentItem,
              remark: this.params.remark,
              noticer: noticer,
              imgUrls: imgUrls
            }
            await this.$api.apply.addRemark(params)
            this.$message.success('提交成功')
            this.$emit('refreshRemark')
            this.show = false
          } catch (err) {
            console.log(err)
          } finally {
            this.loading = false
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.selected-item {
  margin: 5px;
}
</style>
