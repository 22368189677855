//发票申请详情
<template>
  <div>
    <el-descriptions title="发票申请" border :colon="false">
      <el-descriptions-item label="申请单号：">
        {{ orderResult.orderCode }}
      </el-descriptions-item>
	  <el-descriptions-item label="申请人：">
        {{ orderResult.proposerName }}
      </el-descriptions-item>
      <el-descriptions-item label="申请部门：">
        {{ orderResult.applyDepartmentName }}
      </el-descriptions-item>
      <el-descriptions-item label="关联服务收费单：">
        {{ orderResult.relationOrderCode }}
      </el-descriptions-item>
	  <el-descriptions-item label="销售订单号：">
	    {{ orderResult.sellOrderCode }}
	  </el-descriptions-item>
      <el-descriptions-item label="仓库是否已发货：">
        {{ orderResult.shipped == 1 ? '是' : '否' }}
      </el-descriptions-item>
      <el-descriptions-item label="发票票种：">
        {{ invoiceTypeStr }}
      </el-descriptions-item>
      <el-descriptions-item label="客户名称：">
        {{ orderResult.clientName }}
      </el-descriptions-item>
      <el-descriptions-item label="客户税号：">
        {{ orderResult.clientNo }}
      </el-descriptions-item>
      <el-descriptions-item label="电话号码：">
        {{ orderResult.clientPhone }}
      </el-descriptions-item>
      <el-descriptions-item label="开户银行：">
        {{ orderResult.clientBank }}
      </el-descriptions-item>
      <el-descriptions-item label="银行账户：">
        {{ orderResult.clientBankNo }}
      </el-descriptions-item>
      <el-descriptions-item label="客户地址：">
        {{ orderResult.clientAddress }}
      </el-descriptions-item>
      <el-descriptions-item label="客户发票备注：">
        {{ orderResult.remark || '-' }}
      </el-descriptions-item>
      <el-descriptions-item label="附件：">
        <div>
          <FileList
            v-if="orderResult.attachmentUrl"
            :attachmentUrl="orderResult.attachmentUrl"
          />
          <div v-else>-</div>
        </div>
      </el-descriptions-item>
    </el-descriptions>
    <el-divider content-position="left">
      物品清单
      <el-button
        size="mini"
        type="primary"
        @click="downalod"
        style="padding: 3px 5px; margin-left: 10px"
      >
        下载物品清单
      </el-button>
    </el-divider>
    <el-table :data="tableData" show-summary :summary-method="getSummaries">
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        header-align="center"
        align="center"
        prop="code"
        label="料号"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="name"
        label="名称"
      />
      <el-table-column
        header-align="center"
        align="center"
        prop="num"
        label="数量"
      />
      <el-table-column header-align="center" align="center" label="单价">
        <template slot-scope="{ row }">
          {{ row.price | dividedInto }}
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        prop="amount"
        align="center"
        label="金额"
      >
        <template slot-scope="{ row }">
          {{ row.amount | dividedInto }}
        </template>
      </el-table-column>
      <!-- <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="{ row }">
          {{ row.remark || '-' }}
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import detailMixins from './detailMixins.js'
export default {
  mixins: [detailMixins],
  computed: {
    invoiceTypeStr() {
      let invoiceType = this.orderResult.invoiceType
      let name =
        this.$constant.invoiceType.find(a => a.value === invoiceType)?.label ||
        ''
      return name
    },
    tableData() {
      let list = (this.detailResults || []).map(a => {
        return { ...a, amount: a.amount / 100, price: a.price / 100 }
      })
      return list
    }
  },
  methods: {
    downalod() {
      this.$emit('export')
    },
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const showIndexs = [3, 5]
        if (showIndexs.includes(index)) {
          const values = data.map(item => Number(item[column.property]))
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] = this.$format.dividedInto(
            this.$format.fmtAmt(sums[index].toString())
          )
        } else {
          sums[index] = ''
        }
      })

      return sums
    }
  }
}
</script>
